import {
  AppBar,
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";

import Loader from "../Loader";

export default function PdfUpload() {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [nonUnicode, setNonUnicode] = useState("");
  const [tesseractOutput, setTesseractOutput] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleOutputTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleOutputTooltipOpen = () => {
    setOpenTooltip(true);
  };
  const handleChange = (e) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  const saveFile = (e) => {
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!allowedTypes.includes(e.target.files[0].type)) {
      setError("Invalid file type. Please upload a PDF file or an Image File.");
      setFileName("");
    } else {
      setFile(e.target.files[0]);
      setError("");

      setFileName(e.target.files[0].name);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const uploadFile = async (e) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("textInput", input);
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}upload`,
        formData,
        headers
      );
      setOutput(res?.data?.data);
      setNonUnicode(res?.data?.non_unicode);
      setTesseractOutput(res?.data.tesseract_data);
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };

  const highlightEnglishInResponse = (response) => {
    // Use a regular expression to find English words or characters
    const englishText = response.replace(
      /([A-Za-z&0-9]+)/g,
      '<span class="highlight-english">$1</span>'
    );
    return <div dangerouslySetInnerHTML={{ __html: englishText }} />;
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        {loading && <Loader open={loading} />}
        <AppBar sx={{ bgcolor: "#1E3A8A" }} position="static">
          {" "}
          <Toolbar>
            <Typography
              sx={{
                fontFamily: "Nunito",
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-start",
              }}
              variant="h5"
              component="div"
            >
              Home
            </Typography>
            <Button
              onClick={handleLogout}
              className="!font-[Nunito] !text-[18px] !capitalize"
              color="inherit"
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Card className="main-container">
        <Typography
          sx={{ fontFamily: "Nunito Bold" }}
          className="font-[500] font-[Nunito Bold] p-[10px] !text-[26px]"
        >
          Upload Pdf
        </Typography>
        <Typography sx={{ fontFamily: "Nunito Bold", fontSize: "20px" }}>
          Add Text or upload file
        </Typography>
        <Grid container spacing={2}>
          <Grid justifyContent={"center"} item md={12}>
            <TextField
              value={input}
              onChange={handleChange}
              name="input"
              rows={5}
              inputProps={{ style: { fontFamily: "Nunito" } }}
              // disabled={file}
              multiline
              sx={{
                marginBottom: "20px",
                width: "500px",

                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(17, 25, 39, 0.04)",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    backgroundColor: "transparent",
                    borderColor: "#1E3A8A",
                    boxShadow: "#1E3A8A 0px 0px 0px 2px",
                  },
                },
              }}
            />
          </Grid>
        </Grid>

        <div>
          <Button
            component="label"
            variant="contained"
            onChange={saveFile}
            disabled={input !== ""}
            startIcon={<CloudUploadIcon />}
            sx={{
              backgroundColor: "#1E3A8A",
              borderRadius: "15px",
              fontFamily: "Nunito",
              textTransform: "capitalize",
              border: "1px solid #1E3A8A",
              "&:hover": {
                border: "1px solid #1E3A8A",
                backgroundColor: "#fff",
                color: "#1E3A8A",
              },
            }}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              accept="application/pdf, image/jpeg, image/png"
            />
          </Button>
          <Typography>{fileName}</Typography>
          <Typography sx={{ color: "red" }}>{error}</Typography>
        </div>
        <Button
          sx={{
            marginTop: "40px",
            marginBottom: "20px",
            borderRadius: "15px",
            backgroundColor: "#1E3A8A",
            textTransform: "capitalize",
            fontFamily: "Nunito",
            border: "1px solid #1E3A8A",
            "&:hover": {
              border: "1px solid #1E3A8A",
              backgroundColor: "#fff",
              color: "#1E3A8A",
            },
          }}
          variant="contained"
          onClick={uploadFile}
        >
          Submit
        </Button>
        <Button
          sx={{
            marginTop: "40px",
            marginLeft: "20px",
            marginBottom: "20px",
            borderRadius: "15px",
            border: "none",
            color: "#111927",
            textTransform: "capitalize",
            fontFamily: "Nunito",
            "&:hover": {
              border: "none",
            },
          }}
          variant="outlined"
          onClick={() => {
            setFile(null);
            setInput("");
            setOutput("");
            setFileName("");
            setNonUnicode("");
            setError("");
          }}
        >
          Reset
        </Button>
        {nonUnicode && (
          <>
            <Typography sx={{ fontFamily: "Nunito Bold" }}> Output</Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                marginTop: "10px",
                fontFamily: "Nunito",
              }}
            >
              Unicode font
            </Typography>
            <div className="output-data">
              <div>
                
                <Card
                  sx={{
                    padding: "15px",
                    border: "1px solid #e9dfdf",
                    maxWidth: "900px",
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "15px",
                    boxShadow: "5px 2px 10px 10px rgba(0, 0, 0, 0.14)",
                    fontFamily: "Nunito",
                  }}
                >
                  {nonUnicode && (
                    <div className="copy-icon">
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copied"
                        placement="top-start"
                        leaveTouchDelay={500}
                        onMouseLeave={() => setOpen(false)}
                      >
                        <ContentCopyIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleTooltipOpen();
                            navigator.clipboard.writeText(nonUnicode);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  <Typography
                    sx={{ fontWeight: "normal" }}
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {highlightEnglishInResponse(nonUnicode)}
                  </Typography>
                </Card>
              </div>
              {/* <div>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    marginTop: "10px",
                    fontFamily: "Nunito",
                  }}
                >
                  Type 2
                </Typography>
                <Card
                  sx={{
                    padding: "15px",
                    border: "1px solid #e9dfdf",
                    maxWidth: "500px",
                    width: "100%",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "15px",
                    boxShadow: "5px 2px 10px 10px rgba(0, 0, 0, 0.14)",
                    fontFamily: "Nunito",
                  }}
                >
                  {tesseractOutput && (
                    <div className="copy-icon">
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={handleOutputTooltipClose}
                        open={openTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copied"
                        placement="top-start"
                        leaveTouchDelay={500}
                        onMouseLeave={() => setOpenTooltip(false)}
                      >
                        <ContentCopyIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            handleOutputTooltipOpen();
                            navigator.clipboard.writeText(tesseractOutput);
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  <Typography
                  // sx={{ fontWeight: "normal", fontFamily: "GopikaEnglish" }}
                  >
                    {tesseractOutput}
                  </Typography>
                </Card>
              </div> */}
            </div>
          </>
        )}
      </Card>
    </div>
  );
}
