import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Loader(props) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log(props.open, "props.open");
    if (props.open) {
      setOpen(props.open);
    }
  }, [props.open]);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
