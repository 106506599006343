import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Login from "./components/Login";
import PdfUpload from "./components/PdfUpload";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function App() {
  

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PdfUpload />} />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
